<template>
    <div class="nk-app-root">
        <!-- main @s -->
        <div class="nk-main">
            <!-- wrap @s -->
            <div class="nk-wrap nk-wrap-nosidebar">
                <!-- content @s -->
                <div class="nk-content">
                    <div class="nk-block nk-block-middle wide-md mx-auto">
                        <div class="nk-block-content nk-error-ld text-center">
                            <img class="nk-error-gfx" src="@/assets/images/gfx/error-404.svg" alt="">
                            <div class="wide-xs mx-auto">
                                <h3 class="nk-error-title">Página no encontrada</h3>
                                <p class="nk-error-text">Sentimos mucho las molestias. Parece que estás intentando acceder a una página que se ha eliminado o que nunca existió.</p>
                                <a href="#" @click.prevent="back" class="btn btn-lg btn-primary mt-2">Regresar</a>
                            </div>
                        </div>
                    </div><!-- .nk-block -->
                </div>
                <!-- wrap @e -->
            </div>
            <!-- content @e -->
        </div>
        <!-- main @e -->
    </div>
</template>

<script>
    import {useRouter} from 'vue-router';
    export default {
        name: "404",
        setup() {
            const router = useRouter();

            function back(){
                router.back();
            }

            return { back };
        }
    }
</script>

<style scoped>

</style>